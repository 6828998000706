
.left-nav-bar-item a{
    border-radius: 5px 0 0 5px;
    text-align: right;
    padding: 10px 20px 10px 40px;
    display: block;
    margin-bottom: 4px;
    background-color: #ffffff;
    border: 1px solid #dfdfdf;
    border-right: none;
    cursor: pointer;
}
.cat-completed.file-menu-selected:before{
    color: #fff!important;
}
.file-menu-selected{
    background-color: #FF9800!important;
    color: #fff!important;
    border-left: 1px solid #FF9800!important;
    border-right:1px solid #FF9800!important;
}
.left-nav-bar-item a:before{
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f017";
    position: absolute;
    left: 20px;
}
.cat-completed:before{
    content: "\f058"!important;
    color: #2a9055;
}
.right-content{
    padding: 25px;
    min-height: 340px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    width: 84%;
    background-color: #fff;
}
.left-nav-bar{
    padding: 0;
    width: 16%;
    margin-top: 26px;
}
.right-content img{
    max-width: 100%;
    height:auto ;
    padding: 10px;
    max-height: 100%;
    object-fit: contain;
}
.img-wrap{
    width: 230px;
    height: 230px;
    border: 1px solid #ccc;
    margin: 15px;
    position: relative;
}
.img-remove-btn{
    position: absolute;
    top: -4px;
    right: -8px;

}
.img-remove-btn i{
    font-size: 28px;
    background-color: #f8fafc91;
    border-radius: 51px;
    color: #1d1d1d;
}
.img-count{
    margin-top: 10px;
    font-weight: 500;
}
.img-count p{
    font-size: 18px;
}
.img-btn{
    text-align: right;
}
.video-upload{
    text-align: center;
    margin-top: 90px;

}
.btn-footer{
    margin-top: 40px;
}
.btn-footer button{
    position: absolute;
    bottom: 30px;
    right: 30px;
}
.head-content{
    position: relative;
    margin-top: 50px;
}

.header-bar{
    background-color: #fff;
    border: 1px solid #dfdfdf;
    border-radius: 5px;
}

.support-btn {
    top: 17px;
}
.support-btn i {
    font-size: 42px!important;
}
