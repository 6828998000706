
.content-box{
    background-color: #fff;
    min-height: 400px;
    margin-top: 50px;
    border:1px solid #dfdfdf;
    border-radius: 5px;

}
.content-box{
    position: relative;
}

/* first-section */
/*.stripe-ui .first-section{
    padding-top: 70px;
}
*/

/* second-section */
.stripe-ui .first-section{
    margin-top: 50px;
}
.stripe-ui .first-section a{
    display: block;
    width: 220px;
    margin: auto;
}

/* third-section */
.stripe-ui .second-section{
    margin-top: 30px;
}
.stripe-ui .second-section .img-wrap{
    margin: 50px;
    margin-bottom: 80px;
    width: auto;
    height: auto;
}
.stripe-ui .second-section .img-wrap img{
    width: 100%;
}


/* footer-section */
.content-box .dns-footer{
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* domain-new-2 style */
/*.stripe-ui .first-section{
    text-align: left;
    padding: 20px 25px;
}*/
.stripe-ui .first-section{
    margin-top: 40px;
}
.stripe-ui .first-section .title{
    font-size: 24px;
}