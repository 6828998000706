div.message-b {
    opacity: 0;
    display: inline;
    padding: 10px 0;
    position: relative;
    animation-duration: 2.5s;
    -webkit-animation-duration: 2.5s;
    -moz-animation-duration: 2.5s;
}

div.message-b a, div.message-b span {
    margin-left: 5px;
}

div.message-b.in {
    -webkit-animation-name: in; /* Safari 4.0 - 8.0 */
    -moz-animation-name: in; /* Mozilla */
    animation-name: in;
    opacity:1
}
div.message-b.out {
    -webkit-animation-name: out; /* Safari 4.0 - 8.0 */
    -moz-animation-name: out; /* Mozilla */
    animation-name: out;
    opacity:0.01;
}

@-webkit-keyframes in {
    from   {opacity: 0.01;}
    to   {opacity: 1;}
}

@-moz-keyframes in {
    from   {opacity: 0.01;}
    to   {opacity: 1;}
}
@keyframes in {
    from   {opacity: 0.01;}
    to   {opacity: 1;}
}


@-webkit-keyframes out {
    0%   {opacity: 1;}
    75%   {opacity: 0.8;}
    100%   {opacity: 0.01;}
}
@-moz-keyframes out {
    0%   {opacity: 1;}
    75%   {opacity: 0.8;}
    100%   {opacity: 0.01;}
}
@keyframes out {
    0%   {opacity: 1;}
    75%   {opacity: 0.8;}
    100%   {opacity: 0.01;}
}
