.logo-text {
    position: relative;
    z-index: 200;
    top: 85px;
    left: 126px;
}

#app-logo {
    margin-top: -40px;
}

#app-logo p {
    position: relative;
    height: 25px;
}

.main-title {
    margin-top: 40px;
    margin-bottom: 25px
}

.loder {
    text-align: center;
    margin: 0 auto;
    padding: 5px;
    width: 24px;
}

.jrf-popup {
    text-align: center;
}

.validation-error {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: #e3342f;
    font-weight: bold;
}

.accordion-expand{
    max-height: 2000px;
    height: auto;
    transition: max-height 0.50s ease-in;
}

.accordion-collapse{
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
}