

.not-found{
    width: 500px;
    text-align: center;
    margin: auto;
    margin-top: 100px;
}
.not-found i{
    font-size: 90px;
    color: #ff5400;
}