.no-farme {
    border: none !important;
    margin-top: 60px !important;
}

.no-farme .card-header {
    display: none;
}

.secodry-text-g {
    color: #7c8288;
}

.lg-button.selected, .lg-button.selected:hover {
    background-color: #ff7f00;
    border-color: #ff7f00;
}

.lg-button.selected:hover {
    border-color: #03843a !important;
}

.btn-primary {
    color: #fff;
    background-color: #38c172;
    border-color: #38c172;
}

.card-body a:hover, .search-btn:hover, .get-domain:hover, .verify-email-button a:hover, .domain-btn-wrap a:hover {
    background-color: #03843a;
    cursor: pointer;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #ff7f00;
    border-color: #ff7f00;
}

.btn-primary:hover {
    background-color: #03843a !important;
    cursor: pointer;
}

.lg-button {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    color: #fff !important;
    line-height: 62px;
    margin-top: 36px;
    margin-bottom: 20px;
}

.col-center {
    margin: auto;
}

.second-sec {
    margin-top: 40px;
}

/*.right-btn{*/
/*    padding-top: 18px;*/
/*}*/
/*.search-name{*/
/*    padding: 20px;*/
/*}*/

@media (max-width: 767.98px) {
    .lg-button.right-btn {
        margin-top: 0 !important;
    }

    .dns-search-section input, .search-btn {
        border-radius: 5px !important;
    }

    .dns-search-section {
        padding-right: 15px !important;
    }

    .dns-search-section-button {
        padding-left: 15px !important;
    }
}


.form-width {
    max-width: 750px;
    margin: auto;
    /*    margin-top: 10px;*/
}

.search-btn {
    width: 100%;
}

.subdomain label {
    padding-top: 8px;
}

.subdomain p {
    padding-top: 8px;
}

.submit-btn button {
    width: 150px;
    margin-right: -15px !important;
    float: right;
}

.existing-domain {
    margin-right: -15px !important;
}

.submit-btn-next button {
    width: 150px;
    float: right;
}

.get-domain {
    margin: auto;
    width: 150px;
}

.change-domain {
    text-align: center;
}

.change-domain-input .text-input {
    display: block;
    text-align: right;
    font-size: 21px;
    color: #38c14a;
    font-weight: 700;
}

.change-domain-input .text-input button {
    padding-left: 10px;
}

.sub-domain-input, .sub-domain-text {
    display: inline-block;

}

.sub-domain-text {
    adding-top: 5px;
    font-weight: 500;
    letter-spacing: 1px;
    margin-left: 10px;
    font-size: 20px;
}

.verify-email-button a {
    text-align: center;
    display: block;
    width: 200px;
    margin: auto;
    color: #fff !important;
}

.input-td-1 {
    width: 30%;
}

.input-td {
    width: 50%;
}

.input-td-2 {
    width: 20%;
}

.color-white {
    color: white;
}

.domain-btn-wrap a {
    margin: 30px;
    padding: 20px 0;
    color: #fff !important;
    width: 360px;
    font-size: 14px;

}

.domain-btn-wrap {
    text-align: center;
    margin-top: 20px;
}

.domain-title, .domain-title-2 {
    font-size: 18px;
    margin-bottom: 25px;
}

.domain-title-2 {
    margin-top: 70px;
}

.email-verify button {
    float: right;
    margin: 5px;
}

.new-setup-domain {
    margin-top: 20px;
}

.popup-new-domain {
    text-align: center;
}

.popup-new-domain .input-group {
    text-align: center;
    display: block;
    font-size: 18px;
    color: #38c14a;
    font-weight: 500;
}

.new-setup-domain-text {
    display: inline-block;
    padding-left: 10px;
    font-size: 20px;
    font-weight: 500;
}

.new-setup-domain-input {
    display: inline-block;
}

.new-setup-domain button {
    width: 100%;
}

.complete-check {
    font-size: 245px;
    color: #38c172;
    margin-top: -70px;
    margin-bottom: -40px;
}

.complete-text {
    font-size: 20px;
    font-weight: 600;
    margin-top: 100px;
}

.v-from input, #mailbox_id {
    width: 100% !important;
}

.v-from {
    padding-right: 0;
}

.v-from .form-inline .form-group {
    width: 100%;
}

.confirm-email .modal-body .table th, .confirm-email .modal-body .table td {
    border-top: none;
    padding: 4px;
}

.confirm-email .modal-header {
    border-bottom: none;
    padding-bottom: 0;
}

.confirm-email .modal-body {
    padding-top: 0 !important;;
}

.confirm-email .modal-footer {
    border-top: none;
    padding-top: 0 !important;;
}

.modal-body .table th, .modal-body .table td {
    border-top: none;
}

.modal-header {
    border-bottom: none;
}

.modal-footer {
    border-top: none;
}

.modal-title i {
    padding-right: 10px;
    color: #ff8600;
}

.recode-not-uploaded .modal-body {
    padding: 0;
}

.recode-not-uploaded .modal-footer {
    padding-top: 0;
}

.popup-custome-text {
    font-size: 16px;
    font-weight: 600;
}

.new-domain-wrap {
    margin-bottom: 20px;
}

.change-domain-input .text-input:before {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f058";
    padding-right: 10px;
}

.new-sun-domain-wrap {
    text-align: center;
}

.new-sub-select {
    font-size: 20px;
}

.new-d-btn {
    position: relative;
}

.new-d-btn span {
    position: absolute;
    display: block;
    font-size: 10px;
    top: 59px;
    left: 0;
    right: 0;
}

.form-sec {
    margin-left: 0;
    margin-right: 0;
}

.help-dns-modal .modal-body {
    padding: 20px;
}

.help-dns-modal button {
    float: right;
    margin-bottom: 10px;

}

.poup-new-domain-text {
    padding-top: 10px;
    font-size: 20px;
    margin-bottom: 20px;
    color: #38c172;

}

.poup-new-domain-text button {
    margin: 0 !important;
}

.poup-new-domain-text i {
    padding-right: 10px;
}

.get-domain-poup-btn button {
    margin: auto;
    margin-top: 20px;
    margin-bottom: 10px;
}

.help-dns-modal-text {
    margin: 0 20px 20px 20px;

}

.help-dns-modal-text button {
    margin-top: 30px;
    margin-bottom: 15px;
    margin-right: -20px;
}

.form-validation-success {
    padding: 20px;
}

.form-validation-success button {
    float: right;
}

.email-v-subtext {
    font-weight: 500;
    color: #e3342f;
}

.ssl-e-wrap {
    margin: 20px;
    padding: 15px 20px 3px 5px;
    border-radius: 5px;
    background-color: #d4edda;
    display: flex;
}

.ssl-e-wrap p, .ssl-e-wrap img {
    display: inline-block;
}

.ssl-e-wrap img {
    width: 73px;
    height: 65px;
    padding: 0px 10px 20px 10px;
}

.ssl-e-wrap p {
    color: #155724;
}

.share-dns-instruction-wrap {
    padding: 0px 20px 0 16px;
}

.share-dns-instruction-head {
    padding-bottom: 5px;
}

.share-dns-instruction-modal .form-group {
    padding-left: 1px;
}

.share-dns-instruction-modal textarea.form-control {
    min-height: 90px;
}

.new-domain-header3 {
    font-weight: 700;
}

.secondary-color-btn a {
    background-color: #ff7f00;
    border-color: #ff7f00;
}

.secondary-color-btn a:hover {
    background-color: #ffcf00 !important;
    border-color: #ffcf00 !important;
}

.size-guide {
    display: block;
    padding-top: 5px;
    text-decoration: underline;
}

.size-guide:hover {
    background-color: #fff !important;
    text-decoration: underline;
}

.modal-shirt img {
    width: 100%;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
    background-image: none;
}

.search-btn {
    border-radius: 0 5px 5px 0;
}

.dns-search-section {
    padding-right: 0px;
}

.dns-search-section-button {
    padding-left: 0;
}

.dns-search-section input {
    border-radius: 5px 0 0 5px;

}

.app-logo-wrap {
    max-width: 576px;
}

.nav-wrap {
    position: relative;
}

.help-wrap {
    position: relative;
}

.support-btn {
    display: block;
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
}

.support-btn i {
    font-size: 50px;
    color: #38c172;
}

.support-btn-popup.support-box {
    position: absolute;
    z-index: 1;
    top: 66px;
    right: 44px;
    text-align: center;
    background-color: #fff;
    border: 1px solid #38c172;
}

.support-btn-popup.support-box hr {
    margin: 2px;
}

.support-btn-popup.support-box .support-box-subtext {
    margin-bottom: 0;

}

.support-btn-popup.support-box .support-box-title, .support-popup-subtext {
    color: #38c172;
    font-weight: 500;
}

.support-btn-popup.support-box .support-box-title {
    margin-bottom: -5px;
}

.support-box-title-popup {
    font-size: 16px;
    margin-bottom: 5px;
}

.back-home-btn i {
    margin-right: 5px;
}


.content-box {
    background-color: #fff;
    min-height: 400px;
    margin-top: 50px;
    border: 1px solid #dfdfdf;
    border-radius: 5px;

}

.content-box {
    position: relative;
}

/* first-section */
.introing-domain .first-section {
    padding-top: 70px;
}

.introing-domain .first-section p span {
    text-decoration: underline;
}

/* second-section */
.introing-domain .second-section {
    margin-top: 50px;
}

.introing-domain .second-section a {
    display: block;
    width: 220px;
    margin: auto;
}

/* third-section */
.introing-domain .third-section {
    margin-top: 30px;
}

.introing-domain .third-section p {
    margin-bottom: 0;
    color: #6c757d;
}

.introing-domain .third-section a {
    text-decoration: underline;
}

/* footer-section */
.content-box .dns-footer {
    position: absolute;
    bottom: 20px;
    right: 20px;
}

/* domain-new-2 style */
.introing-new-domain .first-section {
    text-align: left;
    padding: 20px 25px;
}

/*.introing-new-domain .second-section{*/
/*    margin-top: 40px;*/
/*}*/
.introing-new-domain .second-section .title {
    font-size: 20px;
}

.introing-new-domain .second-section p span {
    font-weight: 600;
}

.introing-new-domain .third-section .input-group {
    max-width: 550px;
    margin: auto;
    padding: 10px 20px;
}

.introing-domain .back-btn {
    border-color: none !important;
    background-color: rgba(0, 0, 0, .5) !important;
    width: 150px;
    color: #fff;
}

.introing-domain .back-btn:hover {
    background-color: rgba(0, 0, 0, .7) !important;
}

.dns-footer .btn-primary {
    width: 150px !important;
}

.introing-new-domain .alert-danger {
    max-width: 720px;
    margin: auto;
}

.existing-domain-error-alert {
    max-width: inherit !important;
    margin-bottom: 15px !important;
}

.introing-domain, .introing-new-domain .third-section {
    margin-bottom: 70px;
}

.new-sub-domain .new-sub-select {
    font-size: 16px;
}

.new-domain-table {
    width: 80%;
    margin: auto;
}

.new-domain-table th, .new-domain-table td {
    padding: 5px !important;
}
