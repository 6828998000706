.dashboard-head{
    margin-bottom: 30px;
}
.striped-title{
    font-size: 20px!important;
}
.striped-sub-text{
    padding-top: 10px;
    color: #e3342f;
}
