.looking-to-sign-up i{
    font-size: 60px;
}
.looking-to-sign-up h4{
        margin-top: 15px;
}
.looking-to-sign-up .second-section p{
    margin-bottom: 0;
}
.looking-to-sign-up .second-section a{
    margin-top: 5px;
    width: 120px;
}
.third-section{
        margin-top: 20px;
}