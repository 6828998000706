.new-member-btn {
    display: block;
    background-color: #38c172;
    color: #fff !important;
    width: 150px;
    padding: 8px;
    text-align: center;
    border-radius: 5px;
    margin: 35px 0 0 0;
    float: right;
    margin-right: -15px;
}

.invite-popup-wrap input {
    width: 100% !important;
}

.second-btn {
    border: 1px solid #ccc;
}

.btn-primary {
    color: #fff !important;
}

.filter-wrap {
    display: flex;
    position: absolute;
    top: 44px;
    left: 48px;
}

.filter-wrap .form-check {
    margin-top: 45px;
    margin-left: -154px;
}

.filter-wrap .filter-title {
    margin: 0;
    padding-right: 20px;
    line-height: 3;
}

.filter-wrap a {
    line-height: 2;
}
